<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";



export default {
  page: {
    title: "Trajets Effectués",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      tableTrajet: "tableTrajet",
      title: "Trajets Effectués",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Trajets Effectués",
          active: true,
        },
      ],
      idtrajet: 0,
      totalRows: 1,
      currentPage: 1,
      currentPage2: 1,
      pageOptions: [10, 100, 200],
      perPage: 0,
      filter: null,
      filterOn: [],
      sortBy: "dateHeureDepart",
      sortDesc: false,
      fields: [
        { key: "itinéraire", sortable: true },
        { key: "Depart", sortable: true, label: "Départ le" },
        { key: "Arrive", sortable: true, label: "Arrivée le" },
        { key: "fullname", sortable: false, label: "Nom & Prénoms" },
        { key: "type", sortable: false },
        { key: "phone", sortable: false, label: "Téléphone" },
        { key: "prix", sortable: true },
        { key: "action", sortable: true, label: "Relancer" },
      ],

      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      userChargeLoaderTwo: false,
      newDatTrajetPasse: [],
      newStart: false,
      recherche: "",
      rechercheTwo: "",
      newDatTrajetPublier: [],
      cuDatTrajetPublier: [],
      cuDatTrajetPublierBeforeLastSevenDays: [],
      parBon: 500,
      libelLoader: "Chargementt en cours...",
      listePassagers: [],
    };
  },
  async mounted() {
    //Chargement des données

    this.init();
  },
  methods: {
    async init() {
      this.userChargeLoader= true;
      this.userChargeLoaderTwo= true;
      const finTrajet = await apiRequest(
        "GET",
        "admin/fin-trajet-confirmation"
      );

      var finTrajetData = finTrajet.data.trajets;
      console.log(" lis t des trajetstrajets", finTrajetData);

      if (finTrajetData) {
        console.log("fintrajet data");
        console.log(finTrajetData);
        const formattedFinTrajet = finTrajetData.map((ride) => {
          //console.log("ride .p", ride.person);
          var rideUsers = [];
          let currentDate = new Date();
          ride.person.forEach((users) => {
            // Handle empty or null values
            const firstname = users.firstname || "N/A";
            const lastname = users.lastname || "N/A";
            const telephone = users.tel || "N/A";
            const type = users.type || "N/A";
            const confirmation = users.confirmation || false;

            rideUsers.push({
              id: users.id,
              trajet: ride.id,
              firstname: firstname,
              lastname: lastname,
              telephone: telephone,
              type: type,
              confirmation: confirmation,
            });
          });

          const rideDate = new Date(ride.dateHeureDepart);
          const sevenDaysAgo = new Date(
            currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
          );

          if (rideDate >= sevenDaysAgo && rideDate <= currentDate) {
            return {
              itinéraire: ride.lieuDepart + " to " + ride.lieuArrivee,
              Depart: rideDate.toLocaleString("fr-FR"),
              Arrive: new Date(ride.dateHeureArrive).toLocaleString("fr-FR"),
              users: rideUsers,
              prix: ride.prix + " XOF",
              withinLastSevenDays: true,
            };
          } else {
            return {
              itinéraire: ride.lieuDepart + " to " + ride.lieuArrivee,
              Depart: rideDate.toLocaleString("fr-FR"),
              Arrive: new Date(ride.dateHeureArrive).toLocaleString("fr-FR"),
              users: rideUsers,
              prix: ride.prix + " XOF",
              withinLastSevenDays: false,
            };
          }
        });

        // Séparer les données en deux tableaux distincts
        const ridesWithinLastSevenDays = formattedFinTrajet.filter(
          (ride) => ride.withinLastSevenDays
        );
        const ridesBeforeLastSevenDays = formattedFinTrajet.filter(
          (ride) => !ride.withinLastSevenDays
        );

        

        this.cuDatTrajetPublier = ridesWithinLastSevenDays;
        this.cuDatTrajetPublierBeforeLastSevenDays = ridesBeforeLastSevenDays;
        this.rowsdataUser = ridesWithinLastSevenDays.length;
        this.totalRows = formattedFinTrajet.length;
        this.userChargeLoader= false;
        this.userChargeLoaderTwo= false;
       
       
         


      }

    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      console.log(filteredItems);
      this.currentPage = 1;
    },
    goToDetail(row) {
      router.push(`/trajets/details_effectues/${row.id}`);
    },
    // async actif(page, limit) {
    //   //Chargement des données
    //   ///admin/fin-trajet-confirmation
    //   const data = await apiRequest(
    //     "GET",
    //     "admin/trajets/passes?page=" + page + "&limit=" + limit,
    //     undefined,
    //     false
    //   );
    //   if (data && data.data) {
    //     const formattedTable = data.data.map((trajet) => {
    //       var passagers = "";
    //       this.totalValue = data.data.total;
    //       trajet.passagers.forEach((element) => {
    //         if (element.statut == "ACTIF") {
    //           passagers +=
    //             "-" +
    //             element.firstname +
    //             " " +
    //             element.lastname +
    //             " - " +
    //             element.telephone +
    //             "\n";
    //         }
    //       });
    //       return {
    //         d: trajet.id,
    //         itinéraire:
    //           trajet.lieuDepart.nom.split(",")[0] +
    //           " ↦  " +
    //           trajet.lieuArrivee.nom.split(",")[0],
    //         conducteur:
    //           trajet.conducteur != null
    //             ? trajet.conducteur.personne.lastname[0] +
    //               ". " +
    //               trajet.conducteur.personne.firstname
    //             : "",
    //         Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
    //         nombrePlace: trajet.nombrePlace,
    //         prix: trajet.prix + " XOF",
    //         date: new Date(trajet.dateCreation).toLocaleString("fr-FR"),
    //         passagers: passagers,
    //         vues: trajet.vues ? trajet.vues : 0,
    //       };
    //     });
    //     this.newDatTrajetPublier = formattedTable;
    //     this.rowsdataUser = formattedTable.length;
    //     this.totalRows = formattedTable.length;
    //     this.userChargeLoader = false;
    //     this.userChargeLoaderTwo = false;
    //   }
    // },
    // async userSearch() {
    //   console.log(this.recherche);
    //   this.userChargeLoader = true;
    //   const dataSearch = await apiRequest(
    //     "GET",
    //     "admin/trajets/passes-recherche?mots=" + this.recherche,
    //     undefined,
    //     false
    //   );

    //   if (dataSearch && dataSearch.data) {
    //     const formattedTables = dataSearch.data.map((trajet) => {
    //       this.listePassagers = [];
    //       this.totalValue = dataSearch.data.total;
    //       trajet.passagers.forEach((element) => {
    //         if (element.statut == "ACTIF") {
    //           this.listePassagers.push(
    //             `${element.firstname} ${element.lastname}-${element.telephone}`
    //           );
    //         }
    //       });
    //       return {
    //         id: trajet.id,
    //         itinéraire:
    //           trajet.lieuDepart.nom.split(",")[0] +
    //           " ↦  " +
    //           trajet.lieuArrivee.nom.split(",")[0],
    //         conducteur:
    //           trajet.conducteur != null && trajet.conducteur.personne
    //             ? trajet.conducteur.personne.lastname[0] +
    //               ". " +
    //               trajet.conducteur.personne.firstname
    //             : "",
    //         numeroConducteur:
    //           trajet.conducteur != null && trajet.conducteur.personne
    //             ? trajet.conducteur.personne.indicatifTel +
    //               "" +
    //               trajet.conducteur.personne.tel
    //             : null,
    //         Depart: new Date(trajet.dateHeureArrive).toLocaleString("fr-FR"),
    //         prix: trajet.prix + " XOF",
    //         date: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
    //         passagers: this.listePassagers,
    //         vues: trajet.vues ? trajet.vues : 0,
    //       };
    //     });
    //     this.newDatTrajetPublier = formattedTables;
    //     this.totalRows = formattedTables.length;
    //     this.userChargeLoader = false;
    //   }
    // },
    async userSearchTwo() {
      console.log(this.rechercheTwo);
      this.userChargeLoaderTwo = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/trajets/passes-recherche?mots=" + this.rechercheTwo,
        undefined,
        false
      );

      console.log("dddddddd", dataSearch.data);
      if (dataSearch && dataSearch.data) {
        const formattedTables = dataSearch.data.map((trajet) => {
          this.listeUsagers = [];
          this.listeUsagers.push({
            id: trajet.conducteur.id,
            trajet: trajet.id,
            firstname: trajet.conducteur.personne.firstname,
            lastname: trajet.conducteur.personne.lastname,
            telephone: trajet.conducteur.personne.tel,
            type: "CONDUCTEUR",
            confirmation: trajet.conducteur.confirmation,
          });

          this.totalValue = dataSearch.data.total;
          trajet.passagers.forEach((element) => {
            if (element.statut == "ACTIF") {
              this.listeUsagers.push({
                id: element.id,
                trajet: trajet.id,
                firstname: element.firstname,
                lastname: element.lastname,
                telephone: element.tel,
                type: "PASSAGER",
                confirmation: element.confirmation,
              });
            }
          });

          // let currentDate = new Date();
          // ride.person.forEach((users) => {
          //   // Handle empty or null values
          //   const firstname = users.firstname || "N/A";
          //   const lastname = users.lastname || "N/A";
          //   const telephone = users.tel || "N/A";
          //   const type = users.type || "N/A";
          //   const confirmation = users.confirmation || false;

          //   rideUsers.push({
          //     id: users.id,
          //     trajet: ride.id,
          //     firstname: firstname,
          //     lastname: lastname,
          //     telephone: telephone,
          //     type: type,
          //     confirmation: confirmation
          //   });
          // });

          // itinéraire: ride.lieuDepart + " to " + ride.lieuArrivee,
          //   Depart: rideDate.toLocaleString("fr-FR"),
          //   Arrive: new Date(ride.dateHeureArrive).toLocaleString("fr-FR"),
          //   users: rideUsers,
          //   prix: ride.prix + " XOF",
          //   withinLastSevenDays: true,
          return {
            id: trajet.id,
            itinéraire:
              trajet.lieuDepart.nom.split(",")[0] +
              " ↦  " +
              trajet.lieuArrivee.nom.split(",")[0],
            conducteur:
              trajet.conducteur != null && trajet.conducteur.personne
                ? trajet.conducteur.personne.lastname[0] +
                  ". " +
                  trajet.conducteur.personne.firstname
                : "",
            numeroConducteur:
              trajet.conducteur != null && trajet.conducteur.personne
                ? trajet.conducteur.personne.indicatifTel +
                  "" +
                  trajet.conducteur.personne.tel
                : null,
            Arrive: new Date(trajet.dateHeureArrive).toLocaleString("fr-FR"),
            prix: trajet.prix + " XOF",
            Depart: new Date(trajet.dateHeureDepart).toLocaleString("fr-FR"),
            users: this.listeUsagers,
            vues: trajet.vues ? trajet.vues : 0,
          };
        });
        this.cuDatTrajetPublierBeforeLastSevenDays = formattedTables;
        this.totalRows = formattedTables.length;
        this.userChargeLoader = false;
        this.userChargeLoaderTwo = false;
      }
    },
    decrement() {
      this.userChargeLoaderTwo = true;
      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
      console.log("userChargeLoaderTwo", this.userChargeLoaderTwo);
      this.actif(this.startValue, this.endValue);
    },
    increment() {
      this.userChargeLoaderTwo = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
      console.log("userChargeLoaderTwo", this.userChargeLoaderTwo);
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoaderTwo = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
      console.log("userChargeLoaderTwo", this.userChargeLoaderTwo);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoaderTwo = true;
      this.startValue = 1;
      this.endValue = this.parBon;
      console.log("userChargeLoaderTwo", this.userChargeLoaderTwo);
      this.actif(this.startValue, this.endValue);
    },
    showConf(id) {
      console.log("id:", id);
      this.confirmation.forEach((element) => {
        if (element.info.receiver == id) {
          this.confirmation_selected = element.chats;

          this.showMessage = true;
        }
      });
    },
    async resend(trajetId, receiverId) {

      console.log("trajet",trajetId,"receiverId",receiverId)
      const data = await apiRequest(
        "POST",
        "admin/trajets/resend-conf",
        {
          trajet: trajetId,
          receiver: receiverId,
        },
        false
      );
      console.log("data:", data);
      if (data && data.data && data.status == 200) {
        console.log("data:", data);
        this.$toast.success("Utilisateur relancé !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      this.init();
    },
  },
  computed: {
    rows() {
      return this.totalValue;
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
         
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div> 
          <div class="card-body">
            <div
              class="d-flex align-items-center otherThing"
              v-if="userChargeLoader === true"
            >
              <span class="loading">{{ libelLoader }} </span>
              <div aria-hidden="true"></div>
            </div>
            <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
              <div>
                <h4 class="card-title mt-4">
                  Fin Trajets 7 derniers jours : {{ totalRows }}
                </h4>
              </div>
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="row d-flex justify-content-between mt-4">
              <div class="p-3"></div>
              <!-- Search -->
              <div class="inputCol">
                <div class="input">
                  <input
                    type="search"
                    id="serchInput"
                    placeholder="Rechercher..."
                    v-model="recherche"
                    @keyup.enter="userSearch"
                  />
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 mt-2">
              <b-table
                id="trajet"
                :items="
                  cuDatTrajetPublier.length != 0
                    ? cuDatTrajetPublier
                    : tableData
                "
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-clicked="goToDetail"
              >
                <template v-slot:cell(phone)="row" style="text-align: center">
                  <div v-for="(user, index) in row.item.users" :key="index">
                    <a
                      :href="'https://wa.me/' + user.telephone"
                      class="text-success"
                      v-b-tooltip.hover
                      title="Envoyer un message whatsapp"
                      target="_blank"
                    >
                      {{ user.telephone }}
                    </a>
                    <br v-if="index !== row.item.users.length - 1" />
                    <br v-if="index !== row.item.users.length - 1" />
                  </div>
                </template>

                <template
                  v-slot:cell(fullname)="row"
                  style="text-align: center; text-wrap: nowrap"
                >
                  <div v-for="(user, index) in row.item.users" :key="index">
                    <span style="text-wrap: nowrap">
                      {{ user.firstname }} &nbsp; {{ user.lastname }}
                    </span>
                    <br v-if="index !== row.item.users.length - 1" />
                    <br v-if="index !== row.item.users.length - 1" />
                  </div>
                </template>
                <template v-slot:cell(type)="row" style="text-align: center">
                  <div v-for="(user, index) in row.item.users" :key="index">
                    <span>
                      {{ user.type }}
                    </span>
                    <br v-if="index !== row.item.users.length - 1" />
                    <br v-if="index !== row.item.users.length - 1" />
                  </div>
                </template>

                <template v-slot:cell(action)="row" style="text-align: center">
                  <div v-for="(user, index) in row.item.users" :key="index">
                    <button
                      v-if="user.confirmation == true"
                      disabled
                      title="Confirmé"
                      style="background: green; color: white"
                      class="btn mb-0 mr-2"
                      @click="showModal(user.id)"
                    >
                      Confirmé
                    </button>
                    <button
                      v-else
                      title="Renvoyer"
                      style="background: maroon; color: white"
                      class="btn mb-0 mr-2"
                      @click="resend(user.trajet, user.id)"
                    >
                      Relancer
                    </button>
                    <br v-if="index !== row.item.users.length - 1" />
                    <br v-if="index !== row.item.users.length - 1" />
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <transition mode="out-in">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <li>
                        <span class="interval"> {{ startValue }}</span> -
                        <span class="interval">{{ endValue }}</span> sur
                        {{ totalValue }}
                      </li>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <li>
                        <button
                          class="myBouton"
                          @click="firstVal()"
                          :disabled="startValue == 1"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Prem"
                        >
                          <ion-icon name="play-skip-back-outline"></ion-icon>
                        </button>
                        <button
                          type="button"
                          class="myBouton"
                          @click="decrement()"
                          :disabled="endValue == 500"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Préc"
                        >
                          <ion-icon name="chevron-back-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="increment()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Suiv"
                          :disabled="endValue >= totalValue"
                        >
                          <ion-icon name="chevron-forward-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="incrementToLast()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Dern"
                          :disabled="endValue >= totalValue"
                        >
                          <ion-icon name="play-skip-forward-outline"></ion-icon>
                        </button>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <!-- <div class="containerDot" v-if="tableData.length == 0">
           
          </div> -->
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoaderTwo === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div> 
          <div class="card-body">
            <div
              class="d-flex align-items-center otherThing"
              v-if="userChargeLoaderTwo === true"
            >
              <span class="loading">{{ libelLoader }} </span>
              <div aria-hidden="true"></div>
            </div>
            <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
              <div>
                <h4 class="card-title mt-4">Fin Trajets : {{ totalRows }}</h4>
              </div>
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="row d-flex justify-content-between mt-4">
              <div class="p-3"></div>
              <!-- Search -->
              <div class="inputCol">
                <div class="input">
                  <input
                    type="search"
                    id="serchInput2"
                    placeholder="Rechercher..."
                    v-model="rechercheTwo"
                    @keyup.enter="userSearchTwo"
                  />
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 mt-2">
              <b-table
                id="trajetBeforeLastSevenDays"
                :items="
                  cuDatTrajetPublierBeforeLastSevenDays.length != 0
                    ? cuDatTrajetPublierBeforeLastSevenDays
                    : tableData
                "
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(phone)="row" style="text-align: center">
                  <div v-for="(user, index) in row.item.users" :key="index">
                    <a
                      :href="'https://wa.me/' + user.telephone"
                      class="text-success"
                      v-b-tooltip.hover
                      title="Envoyer un message whatsapp"
                      target="_blank"
                    >
                      {{ user.telephone }}
                    </a>
                    <br v-if="index !== row.item.users.length - 1" />
                    <br v-if="index !== row.item.users.length - 1" />
                  </div>
                </template>

                <template
                  v-slot:cell(fullname)="row"
                  style="text-align: center; text-wrap: nowrap"
                >
                  <div v-for="(user, index) in row.item.users" :key="index">
                    <span style="text-wrap: nowrap">
                      {{ user.firstname }} &nbsp; {{ user.lastname }}
                    </span>
                    <br v-if="index !== row.item.users.length - 1" />
                    <br v-if="index !== row.item.users.length - 1" />
                  </div>
                </template>
                <template v-slot:cell(type)="row" style="text-align: center">
                  <div v-for="(user, index) in row.item.users" :key="index">
                    <span>
                      {{ user.type }}
                    </span>
                    <br v-if="index !== row.item.users.length - 1" />
                    <br v-if="index !== row.item.users.length - 1" />
                  </div>
                </template>

                <template v-slot:cell(action)="row" style="text-align: center">
                  <div v-for="(user, index) in row.item.users" :key="index">
                    <button
                      v-if="user.confirmation == true"
                      disabled
                      title="Confirmé"
                      style="background: green; color: white"
                      class="btn mb-0 mr-2"
                      @click="showModal(user.id)"
                    >
                      Confirmé
                    </button>
                    <button
                      v-else
                      title="Relancer"
                      style="background: maroon; color: white"
                      class="btn mb-0 mr-2"
                      @click="resend(user.trajet, user.id)"
                    >
                      Relancer
                    </button>
                    <br v-if="index !== row.item.users.length - 1" />
                    <br v-if="index !== row.item.users.length - 1" />
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <transition mode="out-in">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <li>
                        <span class="interval"> {{ startValue }}</span> -
                        <span class="interval">{{ endValue }}</span> sur
                        {{ totalValue }}
                      </li>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <li>
                        <button
                          class="myBouton"
                          @click="firstVal()"
                          :disabled="startValue == 1"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Prem"
                        >
                          <ion-icon name="play-skip-back-outline"></ion-icon>
                        </button>
                        <button
                          type="button"
                          class="myBouton"
                          @click="decrement()"
                          :disabled="endValue == 500"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Préc"
                        >
                          <ion-icon name="chevron-back-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="increment()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Suiv"
                          :disabled="endValue >= totalValue"
                        >
                          <ion-icon name="chevron-forward-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="incrementToLast()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Dern"
                          :disabled="endValue >= totalValue"
                        >
                          <ion-icon name="play-skip-forward-outline"></ion-icon>
                        </button>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
thead {
  background: #3aaa35;
}

thead div {
  color: #fff;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -18%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.input {
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 250px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 25px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #d6d2f8;
}

.inputCol {
  position: relative;
  justify-content: flex-end;
  margin-right: 13px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}
</style>